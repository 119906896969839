@use 'style/colors';
@use 'style/screen';

.Logo {
    display: flex;
    align-items: center;

    color: colors.$grey900;
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;

    text-decoration: none;

    .logo-title {
        margin-left: 6px;
        margin-right: 48px;

        @include screen.mobile {
            display: none;
        }
    }
}
