@use 'style/colors.scss';

.Notification {
    display: flex;
    align-items: center;
    width: 470px;
    padding: 12px 18px 10px 22px;
    margin-bottom: 10px;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.15);
    border-radius: 5px;

    .icon {
        margin-right: 15px;
    }

    .text {
        width: 350px;
        font-weight: 500;
        font-size: 13px;
        white-space: pre-line;
    }

    .close {
        background: none;
        border: none;
        padding: 0;
        margin-left: auto;
    }

    &.warning {
        background-color: colors.$warningBackground;
    }
    &.info {
        background-color: colors.$black;
    }
    &.success {
        background-color: colors.$blue;
    }
}
