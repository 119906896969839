$blue: blue;
$orange: orange;

$purple300: #d6bbfb;
$purple500: #9e77ed;
$purple600: #7f56d9;
$purple700: #6941c6;
$purple900: #42307d;

$red: red;

$white: #ffffff;
$offWhite: #fcfcfd;

$grey100: #f2f4f7;
$grey300: #d0d5dd;
$grey400: #98a2b3;
$grey500: #667085;
$grey600: #475467;
$grey700: #344054;
$grey800: #1d2939;
$grey900: #101828;

$black: black;

$error50: #fef3f2;
$error200: #fecdca;
$error700: #b42318;

$input: $grey700;
$inputBorder: $grey300;
$inputBackground: $white;

$dropdownText: $grey700;
$dropdownBorder: $grey100;

$primary: $purple600;
$primaryHover: $purple500;

$secondary: $white;
$secondaryHover: $purple300;

$link: $purple700;
$linkHover: $purple500;

$text: $grey800;
$textPrimary: $offWhite;
$textSecondary: $grey800;
$textLight: $grey400;

$warningBackground: $error200;
$errorText: $error700;

$appBackground: $offWhite;
$insetBackground: $white;

$listLoading: $purple300;
