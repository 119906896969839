@use 'style/colors';

.NoteInput {
    flex-grow: 1;

    display: flex;
    flex-direction: column;

    textarea {
        flex-grow: 1;

        resize: none;
        margin-top: 0.5rem;

        border: none;
        outline: none;
        background: none;

        color: colors.$input;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;

        &:focus {
            color: black;
            background-color: white;
        }
    }
}
