@use 'style/screen.scss';

html,
body,
#root {
    height: 100%;
    font-family: 'Inter';
}

.mobile-only {
    @include screen.desktop {
        display: none !important;
    }
}

.desktop-only {
    @include screen.mobile {
        display: none !important;
    }
}
