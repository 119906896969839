@use 'style/colors';

.Auth {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;

    .auth-header {
        display: flex;

        height: 120px;
        width: 100%;
        max-width: 1200px;
        margin: 0 auto;
    }

    .auth-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 1rem;
    }
}

.Login,
.SignUp,
.ForgotPassword,
.ResetPassword {
    max-width: 22.5rem;
    width: 100%;

    h2 {
        margin-top: 0rem;
        font-weight: 600;
        font-size: 36px;
        line-height: 44px;
        margin-bottom: 0.75rem;
    }

    .subheading {
        font-size: 16px;
        line-height: 24px;
        color: colors.$grey500;
        margin-bottom: 2rem;
    }

    form {
        display: flex;
        gap: 0.5rem;
        flex-direction: column;

        label {
            font-weight: 500;
            font-size: 14px;
            color: colors.$grey700;
        }

        input {
            height: 44px;
            border: 1px solid colors.$inputBorder;
            border-radius: 0.5rem;
            background-color: colors.$inputBackground;
            color: colors.$input;
            padding: 10px 14px;
            margin-bottom: 1.25rem;

            &:focus {
                color: colors.$black;
            }
        }
    }

    .Button {
        padding: 0.625rem;
        background: colors.$primary;
        border-radius: 0.75rem;

        color: colors.$white;
        font-size: 1rem;
        line-height: 1.5em;
        font-weight: 500;

        &:hover {
            background: colors.$primaryHover;
        }
    }

    a {
        font-size: 14px;
        line-height: 20px;
        color: colors.$link;
        text-decoration: none;

        &:hover {
            color: colors.$linkHover;
        }
    }
}

.Login {
    .forgot {
        text-align: right;
        margin-top: -1rem;
        margin-bottom: 1rem;
    }

    .sign-up {
        margin-top: 2rem;
        text-align: center;
        font-size: 14px;
        color: colors.$grey500;
    }
}
