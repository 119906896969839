@use 'style/colors';

.ConflictEditor {
    height: 100%;
    height: -webkit-fill-available;

    padding: 0 0 1rem 0;

    width: 0;
    flex-grow: 1;

    display: flex;
    flex-direction: column;

    position: sticky;
    top: 0;

    .NoteInput {
        textarea {
            &,
            &:focus {
                background-color: colors.$error50;
            }
        }
    }
}
