@use 'style/screen';

.NotesHeader {
    flex-shrink: 0;

    display: flex;

    align-items: center;
    height: 120px;
    width: 100%;
    max-width: 1200px;
    margin: auto;

    gap: 16px;

    @include screen.mobile {
        height: 70px;
    }
}
