@use 'style/colors';
@use 'style/screen';

.NotesList {
    @include screen.mobile {
        display: none;

        &:only-child {
            display: flex;
        }
    }

    width: 0;
    flex-grow: 1;
    max-width: 600px;

    display: flex;
    flex-direction: column;

    .notes {
        padding-top: 4px;
    }

    .Note {
        display: block;
        color: black;
        text-decoration: none;

        margin-bottom: 1rem;
        padding: 1rem;
        background: colors.$insetBackground;

        box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1),
            0px 1px 2px rgba(16, 24, 40, 0.06);
        border-radius: 4px;

        .timestamp {
            color: colors.$textLight;

            font-weight: 500;
            font-size: 12px;
            line-height: 18px;

            margin-bottom: 3px;
        }

        .title {
            $color: colors.$text;

            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
        }

        .preview {
            color: colors.$textLight;

            font-weight: 400;
            font-size: 12px;
            line-height: 18px;
            white-space: pre;
            overflow: hidden;
            text-overflow: ellipsis;

            margin-top: 4px;
        }

        .line-count {
            color: colors.$textLight;

            font-weight: 500;
            font-size: 12px;
            line-height: 18px;

            margin-top: 3px;
        }
    }

    .Loading {
        position: sticky;
        bottom: -0.75rem;

        text-align: center;

        padding-bottom: 1.25rem;
        margin-top: 0.5rem;
        border-radius: 0.75rem;

        background-color: colors.$listLoading;
    }
}

.NoNotes {
    position: absolute;
    width: 100%;
    margin-top: 4rem;

    display: flex;
    flex-direction: column;
    align-items: center;

    color: colors.$grey400;
    font-weight: 500;
    font-size: 20px;
    line-height: 32px;

    .Button {
        margin-top: 1rem;
    }
}
