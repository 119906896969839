@use 'style/colors';
@use 'style/screen';

.SearchBar {
    position: relative;

    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    height: 32px;

    .search-icon {
        position: absolute;
        left: 14px;
    }

    .close {
        position: absolute;
        right: 0px;

        display: flex;
        align-items: center;
        justify-content: center;

        width: 44px;
        height: 44px;
        border-radius: 8px;

        &:hover {
            background-color: colors.$secondaryHover;
        }
    }

    input {
        width: 100%;
        height: 44px;
        padding-left: 46px;
        padding-right: 46px;

        color: colors.$input;
        border: 1px solid colors.$inputBorder;
        border-radius: 8px;

        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
    }

    @include screen.mobile {
        .search-icon {
            display: none;
        }

        input {
            padding-left: 14px;
        }
    }
}
