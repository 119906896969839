.Notes {
    height: 100%;
    width: 100%;
    max-width: 1200px;

    // for note card shadow
    margin-left: -4px;
    margin-right: -4px;
    padding-left: 4px;
    padding-right: 4px;

    gap: 12px;

    display: flex;
    flex-direction: row;

    overflow: scroll;
}
