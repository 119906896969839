@use 'style/colors.scss';

.Notifications {
    position: fixed;
    bottom: 4rem;
    left: 4rem;
    z-index: 3;

    display: flex;
    flex-direction: column-reverse;

    color: white;
}
