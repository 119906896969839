@use 'style/colors';

.HeaderMenu {
    margin-top: 4px;
    min-width: 160px;
    overflow: hidden;

    background-color: colors.$insetBackground;
    outline: 1px solid colors.$dropdownBorder;
    border-radius: 8px;
    box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08),
        0px 4px 6px -2px rgba(16, 24, 40, 0.03);

    div[role='menuitem'] {
        padding: 10px 16px;

        display: flex;
        align-items: center;

        font-size: 14px;
        line-height: 20px;
        color: colors.$dropdownText;

        img {
            margin-right: 12px;
        }

        &:hover {
            background-color: colors.$secondaryHover;

            &.warning {
                background-color: colors.$warningBackground;
            }
        }
    }

    div[role='separator'] {
        border-top: 1px solid colors.$dropdownBorder;
    }
}
