@use 'style/colors';

.Button {
    border: none;
    background-color: inherit;
}

a.Button {
    display: inline-block;
    text-align: center;
    text-decoration: none;
    padding: 1px 4px;

    &,
    &:visited {
        color: black;
    }
}

@mixin primary {
    padding: 10px 18px;
    border-radius: 8px;
    font-weight: 500;
    font-size: 16px;
    height: 44px;

    flex-shrink: 0;

    &,
    &:visited {
        color: colors.$textPrimary;
    }
}

.Button.primary {
    @include primary;

    background: colors.$primary;

    &:hover {
        background: colors.$primaryHover;
    }
}

.Button.secondary {
    @include primary;

    &,
    &:visited {
        color: colors.$textSecondary;
    }

    background: colors.$secondary;
    border: 1px solid colors.$inputBorder;

    &:hover {
        background: colors.$secondaryHover;
    }
}

.Button.warning {
    @include primary;

    background: colors.$warningBackground;

    &:hover {
        filter: opacity(0.8);
    }
}
