@use 'style/colors.scss';

.App {
    display: flex;
    flex-direction: column;
    align-items: center;

    height: 100%;
    width: 100vw;

    padding: 0 12px;

    background-color: colors.$appBackground;
}
